import React from "react";
import "../../src/index.css";
import { useTranslation } from "react-i18next";

const Experience = () => {
  const [t] = useTranslation("global");
  return (
    <div
      id="experience"
      className="flex flex-col bg-[#6B94AE] items-center justify-center p-8 "
    >
      <div>
        <h2 className="font-bold text-[#fff] sm:text-[#fff] md:text-[#fff] lg:text-[#fff] xl:text-[#fff] mb-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
          {t("traduccion.tituloNosotros")}
        </h2>
      </div>
      <p className="text-center text-[#fff] misiontexto px-4 text-sm md:text-base lg:text-lg xl:text-xl w-[80%]">
        {t("traduccion.mision")}
      </p>
      <p className="text-center text-[#fff] misiontexto px-4 text-sm md:text-base lg:text-lg xl:text-xl w-[80%]">
        {t("traduccion.nosotros")}
      </p>
    </div>
  );
};

export default Experience;
