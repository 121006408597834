// import React, { useState } from "react";
// import { Turn as Hamburger } from 'hamburger-react'
// import LogoCai from "../images/nuevo.svg";
// import { useTranslation } from "react-i18next";

// const Navbar = () => {
//   const { t, i18n } = useTranslation("global");
//   const [isOpen, setOpen] = useState(false);

//   const toggleMenu = () => {
//     setOpen(!isOpen);
//   };

//   return (
//     <div id="home" className="w-full h-[60px] bg-[#fff]">
//       <div className="flex items-center justify-between h-[60px]">
//         <img
//           className="logoCai"
//           height={140}
//           width={140}
//           src={LogoCai}
//           alt="logo"
//         />

//         <ul className="hidden text-[#004774] h-fit md:flex items-center justify-center gap-5 font-bold text-lg mr-4">
//           <li className="hover:text-xl duration-300 ease-in-out text-center">
//             <a href="#home">CAI</a>
//           </li>



//           <li className="hover:text-xl duration-300 ease-in-out">
//             <a href="#aboutus">{t("traduccion.navaboutus")}</a>
//           </li>

//           <li className="hover:text-xl duration-300 ease-in-out">
//             <a href="#experience">{t("traduccion.navExperience")}</a>
//           </li>

//                    <li className="hover:text-xl duration-300 ease-in-out">
//             <a href="#opportunity">{t("traduccion.navOpportunity")}</a>
//           </li>

//           <li className="hover:text-xl duration-300 ease-in-out">
//             <a href="#services">{t("traduccion.navServices")}</a>
//           </li>


//           <li className="hover:text-xl duration-300 ease-in-out">
//             <a href="#contacto">{t("traduccion.navContacto")}</a>
//           </li>

//         </ul>

//         <div className="md:hidden block">
//           <Hamburger  
//             buttonColor="white" 
//             buttonWidth={20} 
//             isActive={isOpen}
//             toggleButton={toggleMenu} 
//           />
//         </div>
//       </div>
//       {/* <div className={`${isOpen ? "block" : "hidden"}  mt-4`}> */}
//       <div >
//         <ul className="text-[white] flex justify-start items-center flex-col font-bold text-2xl h-[100vh] p-4">
//           <li className="mb-5 border-b-2 border-white text-center">
//             <a href="#home">CAI</a>
//           </li>
//           <li className="mb-5 border-b-2 border-white">
//           <a href="#aboutus">{t("traduccion.navaboutus")}</a>
//           </li>

//           <li className="mb-5 border-b-2 border-white">
//             <a href="#quehacemos">{t("traduccion.navHacemos")}</a>
//           </li>
//           <li className="mb-5 border-b-2 border-white">
//             <a href="#valores">{t("traduccion.navValores")}</a>
//           </li>

//           <li className="mb-5 border-b-2 border-white">
//             <a href="#contacto">{t("traduccion.navContacto")}</a>
//           </li>
//         </ul>
//       </div>
//       </div>

//   );
// };

// export default Navbar;


import React, { useState } from "react";
import Hamburger from 'hamburger-react'
import LogoCai from "../images/nuevo.svg";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t, i18n } = useTranslation("global");
  const [isOpen, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <div id="home" className="w-full h-[60px] bg-[#fff]">
        <div className="flex items-center justify-between h-[60px]">
          <img
            className="logoCai"
            height={140}
            width={140}
            src={LogoCai}
            alt="logo"
          />

          <ul className="hidden text-[#004774] h-fit md:flex items-center justify-center gap-5 font-bold text-lg mr-4">
            <li className="hover:text-xl duration-300 ease-in-out text-center">
              <a href="#home">CAI</a>
            </li>
            <li className="hover:text-xl duration-300 ease-in-out">
              <a href="#experience">{t("traduccion.navExperience")}</a>
            </li>
            <li className="hover:text-xl duration-300 ease-in-out">
              <a href="#opportunity">{t("traduccion.navOpportunity")}</a>
            </li>
            <li className="hover:text-xl duration-300 ease-in-out">
              <a href="#services">{t("traduccion.navServices")}</a>
            </li>
            <li className="hover:text-xl duration-300 ease-in-out">
              <a href="#contacto">{t("traduccion.navContacto")}</a>
            </li>
          </ul>

          <div className="md:hidden block">
            <Hamburger
              buttonColor="white"
              buttonWidth={20}
              isOpen={isOpen}
              onToggle={toggleMenu}
            />
          </div>
        </div>
      </div>

      <div className={`md:hidden ${isOpen ? "h-screen" : "h-0"} overflow-hidden transition-all duration-300`}>
        <ul className=" text-[#004774] flex justify-start items-center flex-col font-bold text-2xl h-[100vh] p-4">
          <li className="hover:text-xl  mb-5 border-b-2 duration-300 ease-in-out">
            <a href="#home">CAI</a>
          </li>
        
          <li className="hover:text-xl  mb-5 border-b-2 duration-300 ease-in-out">
              <a href="#experience">{t("traduccion.navExperience")}</a>
            </li>
            
            <li className="hover:text-xl  mb-5 border-b-2 duration-300 ease-in-out">
              <a href="#opportunity">{t("traduccion.navOpportunity")}</a>
            </li>


            <li className="hover:text-xl mb-5 border-b-2 duration-300 ease-in-out">
              <a href="#services">{t("traduccion.navServices")}</a>
            </li>
          
          <li className="hover:text-xl mb-5 border-b-2 duration-300 ease-in-out">
            <a href="#contacto">{t("traduccion.navContacto")}</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
