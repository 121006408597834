import React from 'react'
import FotoPortada from "../../src/images/foto portada.jpeg";

import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation("global");
    return (
        <div className="w-full relative  sm:h-[300px]  md:h-[500px]  lg:h-[800px] ">
            <img
                className=" h-full w-full  filter brightness-[.7] object-fit"
                src={FotoPortada}
                alt="fotoPortada"
            />

<h2 className="text-white font-bold sm:text-xl md:text-4xl absolute top-[30%] left-[50%] text-center transform -translate-x-[50%] -translate-y-[50%]">
    {t("traduccion.titleAcompanamos")}
</h2>

        </div>
    )
}

export default Header;