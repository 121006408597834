import React from "react";
import "../../src/index.css";
import { AiOutlineMail } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { useTranslation } from "react-i18next";

import WhatsApp from "../images/whatsapp.svg";

const Footer = () => {
  const [t] = useTranslation("global");

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      id="contacto"
      className="flex flex-col items-center mt-8 sm:mt-6 md:mt-8 lg:mt-10 xl:mt-12 mx-auto"
    >
      <h2 className="font-bold  text-[#004774] sm:text-[#004774] md:text-[#004774] lg:text-[#004774] xl:text-[#004774]  text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
        {t("traduccion.tituloContacto")}
      </h2>

      <div className="flex flex-col items-center w-full p-2 sm:p-4 md:p-6 lg:p-8 xl:p-10">
        <div className="flex items-center pb-2 sm:pb-4">
          <AiOutlineMail className="mr-2 text-base   text-[#004774] sm:text-base" />
          <p className="text-sm md:text-base  text-[#004774] lg:text-lg xl:text-xl">
            {t("traduccion.email")}
          </p>
        </div>
        <div className="flex items-center pb-2 sm:pb-4">
          <GoLocation className="mr-2 text-base   text-[#004774] sm:text-base" />
          <p className="text-sm md:text-base   text-[#004774] lg:text-lg xl:text-xl">
            {t("traduccion.direccion")}
          </p>
        </div>

        <div className="flex items-center pb-2 sm:pb-4">
          <img src={WhatsApp} alt="WhatsApp Icon" className="mr-2 w-4 " />
          <p className="text-sm md:text-base   text-[#004774] lg:text-lg xl:text-xl">
            <a
              href="https://api.whatsapp.com/send?phone=+541123726604&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20."
              className="float"
              target="_blank"
              rel="noreferrer"
            >
              {t("traduccion.telefono")}
              <br />
            </a>
          </p>
        </div>
        <div className="flex items-center pb-2 sm:pb-4 justify-center">
          <p className="text-sm md:text-base text-[#004774] lg:text-lg xl:text-xl text-center">
            {t("traduccion.registro")}
          </p>
        </div>
        <div className="flex items-center pb-2 sm:pb-4 justify-center">
          <p className="text-sm md:text-base text-[#004774] lg:text-lg xl:text-xl text-center">
            {t("traduccion.registroSwiss")}
          </p>
        </div>
      </div>

      <div className="mb-5">
        <button
          className="bg-blue-400 hover:bg-blue-600 text-white font-bold py-1 px-2 text-base rounded-lg mb-2 "
          onClick={scrollToTop}
        >
          Scroll to Top
        </button>
      </div>
      <div className="flex pb-4 flex-col sm:flex-row justify-center lg:justify-end items-center w-full px-2 sm:px-4 md:px-6 lg:px-8 xl:px-10 mb-2 sm:mb-4 md:mb-6 lg:mb-8 xl:mb-10">
        <p className="text-sm md:text-base text-[#004774] lg:text-lg xl:text-xl text-center">
          {t("traduccion.derechos")}
        </p>
      </div>
    </div>
  );
};

export default Footer;
