import React from "react";
import { useTranslation } from "react-i18next";
import AsesorFinanciero from "../images/Analsisi financiero.jpeg";
import GestionInmobiliaria from "../images/developer.jpeg";
import "../../src/index.css";

const Services = () => {
  const [t] = useTranslation("global");

  // Define a Tailwind CSS class for the image container
  const imageContainerClass =
    "m-4 text-center transform hover:scale-105 transition-transform duration-300 ease-in-out w-full h-full";

  // Define a Tailwind CSS class for the image itself
  const imageClass = "h-60 sm:h-60 w-80 object-cover"; // Adjust image dimensions here

  return (
    <div style={{ backgroundColor: "#6b94ae", color: "white", minHeight: "80vh", paddingBottom: "4rem" }}>
      <div
        id="services"
        className="flex flex-col items-center justify-center mt-8 px-6 md:mt-20"
      >
        <h2 className="font-bold  text-[#fff] sm:text-[#fff] md:text-[#fff] lg:text-[#fff] xl:text-[#fff] text-2xl titulos sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl p-5 md:p-10">
          {t("traduccion.tituloServices")}
        </h2>
      </div>

      <div className="mt-10 grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        <div className={imageContainerClass}>
          <div className="flex flex-col justify-center items-center h-full">
            <img
              className={imageClass}
              src={AsesorFinanciero}
              alt="asesoramientoFinanciero"
            />
            <div className="w-4/5">
              <h2 className="font-bold pt-4 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
                {t("traduccion.tituloAsesor")}
              </h2>

              <p className="text-justify pt-2 text-sm md:text-base lg:text-lg xl:text-xl ">
                {t("traduccion.asesoraFinanciero")}
              </p>
              <ul className="list-disc m-auto w-4/5 text-left pt-4 text-sm md:text-base lg:text-lg xl:text-lg">
                <li>{t("traduccion.bulletsAsesor1")}</li>
                <li>{t("traduccion.bulletsAsesor2")}</li>
                <li>{t("traduccion.bulletsAsesor3")}</li>
              </ul>
            </div >
          </div>
        </div>

        <div className={imageContainerClass}>
          <div className="flex flex-col justify-center items-center h-full">
            <img
              className={imageClass}
              src={GestionInmobiliaria}
              alt="gestionInmobiliaria"
            />
            <div className="w-4/5 ">
              <h2 className="font-bold pt-4 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
                {t("traduccion.tituloInmobiliaria")}
              </h2>
              <p className="text-justify pt-2 text-sm md:text-base lg:text-lg xl:text-xl">
                {t("traduccion.gestionInmobiliria")}
              </p>
              <ul className="list-disc m-auto w-4/5 text-left pt-4 text-sm md:text-base lg:text-lg xl:text-lg">
                <li>{t("traduccion.bulletsAsesor4")}</li>
                <li>{t("traduccion.bulletsAsesor5")}</li>
                <li>{t("traduccion.bulletsAsesor6")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
